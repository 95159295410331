// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"


import AniLink from "gatsby-plugin-transition-link/AniLink";


import "../styles/style.scss"

class Information extends Component {


    render() {


        return (

        <Layout noHeader noFooter>

            <SEO title="Polygon Dynamics"/>
            <div className="headerInformation">
                <div className="row row___max">
                    <div className="col col4">
                        <h2 className="logo"><AniLink fade duration={0.5} to="/">— Polygon Dynamics</AniLink></h2>
                    </div>
                    <div className="col col4">
                        <div className="row">
                            {true &&
                              <>

                                <p className="menuItem hidden_iphonePlus"><AniLink fade duration={0.5} to="/archive">Archive</AniLink></p>
                              </>
                            }
                            <p className="menuItem">~</p>
                            <p className="menuItem"><AniLink fade duration={0.5} to="/fashion">Fashion</AniLink></p>
                            <p className="menuItem"><AniLink fade duration={0.5} to="/services">Services</AniLink></p>
                            <p className="menuItem"><AniLink fade duration={0.5} to="/information">Information</AniLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="information">
                <div className="informationBg"></div>
                <div className="row row___max ">
                    <div className="col col4">
                        <h3 className="title marginBottom">Information</h3>
                        <p className="texte marginBottom">Polygon Dynamics is a motion and design studio founded by <a target="_blank" rel="noreferrer" href="https://gaspardbruno.com/">Gaspard + Bruno</a>, it combines new technologies and processes to craft unique visual experiences and 3d imagery at the edge of fashion; introducing a new approach of digital visualisation without using physical elements.</p>
                        <p className="texte marginBottom">→ <a target="_blank" rel="noreferrer" href="https://gaspardbruno.com/">Gaspard + Bruno</a></p>

                        <p className="texte texteSmall">Copyright by Gaspard+Bruno Ltd, {new Date().getFullYear()}. All rights reserved. All materials on this website are intellectual property of Gaspard+Bruno Ltd. Texts and photos present on this site are not royalty free. All rights for representation and reproduction reserved.</p>
                    </div>
                    <div className="col col4">
                        <h3 className="title marginBottom">Contact</h3>
                        <p className="texte marginBottom">→ <a href="mailto:hello@gaspardbruno.com" target="_blank" rel="noopener">hello@gaspardbruno.com</a></p>
                        <p className="texte marginBottom">Follow us : <a href="https://www.instagram.com/gaspardbruno/" rel="noreferrer" target="_blank">Instagram</a> / <a href="https://github.com/Gaspard-Bruno/" rel="noreferrer" target="_blank">Github</a> / <a href="https://twitter.com/gaspardbruno/" rel="noreferrer" target="_blank">Twitter</a> / <a href="https://www.facebook.com/gaspardbrunoagency/" rel="noreferrer" target="_blank">Facebook</a></p>

                        <h3 className="title marginBottom">Registered offices</h3>
                        <p className="texte marginBottom">— Second Home London</p>

                        <p className="texte">68 Hanbury Street,</p>
                        <p className="texte">London, E1 5JL</p>
                        <p className="texte marginBottom">United Kingdom</p>

                        <p className="texte marginBottom">— Second Home Lisbon</p>

                        <p className="texte">1st Floor – Time Out Market Lisboa Mercado da Ribeira,</p>
                        <p className="texte">Avenida 24 de Julho,</p>
                        <p className="texte marginBottom">1200–479 Lisboa, Portugal</p>


                    </div>
                </div>
            </div>
            <div className="informationVideo">
                <video preload="buffer" playsInline loop muted autoPlay>
                    <source src="/Cloth_01.mp4" type="video/mp4"></source>
                </video>
            </div>

        </Layout>

        )
    }

}

export default Information
